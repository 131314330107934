import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import qs from 'qs';
import React, { Component } from 'react';
// import get from 'lodash/get';
import { Index } from 'elasticlunr';
import TextFieldMUI from '@material-ui/core/TextField';
import { TextFieldLunrSearch } from 'layouts/jss';
import logger from 'utils/logger';

const getSearch = (queryString) => {
  if (!queryString) return '';
  if (!queryString.q) return '';
  return queryString.q;
};

export default class Search extends Component {
  constructor (props, context) {
    super(props, context);

    // Create the ref
    this.searchRef = React.createRef();

    this.updateQuery = (evt) => {
      let text = evt.target ? evt.target.value : '';
      if (evt.target) {
        if (evt.target.value) {
          navigate(`${pathname}?q=${evt.target.value}`);
        } else {
          navigate(`${pathname}`);
        }
      }
      logger.debug(`updateQuery> queryString`, queryString);
      if (!text && queryString.q) {
        text = queryString.q;
      }
      const newQuery = qs.stringify({ q: text }, { format: 'RFC1738' });
      const hits = this.getHits(text);
      this.props.onSearch(text, hits);
      logger.query(`Search constructor () > newQuery`, newQuery);
      this.setState((s) => {
        return {
          ...s,
          hits,
          query: text
        };
      });
    };

    let queryString = '';
    let pathname = '';
    if (typeof window !== 'undefined') {
      queryString = qs.parse(window.location.search.substring(1));
      pathname = window.location.pathname;
    }
    logger.query(`Search constructor () > props`, props);

    const query = getSearch(queryString);

    this.state = {
      query: query || '',
      hits: this.getHits(query)
    };
  }

  componentWillMount () {
    this.updateQuery(this.searchRef);
  }

  getHits (query) {
    if (!query) return [];

    if (!this.index) this.createIndex();
    const hits = this.index.search(query, { expand: true });
    return hits.map(({ ref }) => this.index.documentStore.getDoc(ref));
  }

  createIndex () {
    this.index = Index.load(this.props.data.index);
  }

  render () {
    const { query } = this.state;
    // logger.query(`Search render () > this.state`, this.state);

    return (
      <div>
        <div role={`search`}>
          <TextFieldMUI
            id={`search`}
            ref={this.searchRef}
            label={this.props.label || `Search`}
            className={`mui mui-search mui-search-xl mb-20em ${this.props.className}`}
            {...TextFieldLunrSearch}
            value={query}
            placeholder={this.props.placeholder}
            onChange={this.updateQuery}
            type={`search`}
          />
        </div>
      </div>
    );
  }
}

Search.propTypes = {
  data: PropTypes.shape({
    index: PropTypes.object.isRequired
  }).isRequired,
  onSearch: PropTypes.func.isRequired
};
