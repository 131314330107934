import colors from 'layouts/scss/colors.scss';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { /*withStyles, MuiThemeProvider, */createMuiTheme } from '@material-ui/core/styles';

export const muiTheme = getMuiTheme({
  palette: {
    primary1Color: colors[`primary-500`],
    accent1Color: colors[`primary-500`],
    secondary1Color: colors[`primary-500`],
    secondary2color: colors[`primary-500`]
  },
  appBar: {
    height: 50
  }
});

export const theme = createMuiTheme({
  palette: {
    // primary: colors[`primary-500`],
    main: '#ff4400',
  },
  typography: { useNextVariants: true },
  cssUnderline: {
    '&:after': {
      borderBottomColor: `#ff0000`,
    },
  },
});

const TextFieldDarkStyles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  cssLabel: {
    '&$cssFocused': {
      color: colors[`primary-500`],
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: colors[`primary-500`],
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: colors[`primary-500`],
    },
  },
  notchedOutline: {},
  bootstrapRoot: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
});

export const TextFieldDark = {
  InputLabelProps: {
    classes: {
      root: TextFieldDarkStyles.cssLabel,
      focused: TextFieldDarkStyles.cssFocused,
      color: `red`
    },
  },
  InputProps:{
    classes: {
      root: TextFieldDarkStyles.cssOutlinedInput,
      focused: TextFieldDarkStyles.cssFocused,
      notchedOutline: TextFieldDarkStyles.notchedOutline,
    },
  }
};

export const TextFieldDarkOld = {
  style: {
    height: `3.9em`,
    top: `-30px`,
    color: colors[`indigo-100`],
    minWidth: `300px`
  },
  inputStyle: {
    color: colors[`indigo-100`],
    padding: `0 .75em 0`,
    height: `2.5em`,
    fontSize: `1em`
  },
  floatingLabelStyle: {
    color: colors[`gray-cold-200`],
    padding: `0 .95em 1.5em`,
    opacity: 0.5,
    // fontSize: '15px',
    top: '22px'
  },
  floatingLabelFocusStyle: {
    color: colors[`gray-cold-100`],
    opacity: 0.9
    // fontSize: '215px',
    // 18px
  },
  underlineStyle: {
    borderWidth: '2px',
    color: colors[`gray-cold-300`],
    opacity: 0.6
  },
  underlineFocusStyle: {
    borderColor: colors[`indigo-100`],
    opacity: 1
  },
  errorStyle: {
    position: `absolute`,
    bottom: `-1em`,
    left: `.95em`,
    fontWeight: `bold`,
    textTransform: `uppercase`,
    fontSize: `.7em`,
    letterSpacing: `.015em`,
    color: colors[`danger-500`]
  }
};
export const TextFieldLunrSearch = {
  style: {
    height: `auto`,
    // top: `2px`,
    minWidth: `100%`,
    width: `1px`
  },
  inputStyle: {
    // color: colors[`indigo-100`],
    padding: `1.1em .25em 1em`,
    // height: `3.5em`,
    fontWeight: `600`,
    fontSize: `24px`
  },
  floatingLabelStyle: {
    // color: colors[`gray-cold-200`],
    // padding: `0 .95em 1.5em`,
    fontSize: `24px`,
    opacity: 0.5,
    // fontSize: '15px',
    padding: `1em .25em`,
    top: '20px'
  },
  floatingLabelShrinkStyle:{
    fontSize: '20px',
  },
  floatingLabelFocusStyle: {
    // color: colors[`gray-cold-100`],
    opacity: 0.9,
    // top: `15px`
    // 18px
  },
  underlineStyle: {
    borderWidth: '2px',
    // color: colors[`gray-cold-300`],
    opacity: 0.6
  },
  underlineFocusStyle: {
    // borderColor: colors[`indigo-100`],
    opacity: 1
  },
};
export const TextFieldResourceSearch = {
  style: {
    height: `3.9em`,
    top: `2px`,
    minWidth: `100%`,
    width: `1px`
  },
  inputStyle: {
    color: colors[`gray-cold-500`],
    padding: `0 .25em 0`,
    height: `2.5em`,
    fontSize: `1em`
  },
  floatingLabelStyle: {
    color: colors[`gray-cold-700`],
    padding: `0 .95em 1em`,
    opacity: 0.5,
    // fontSize: '15px',
    top: '22px'
  },
  floatingLabelFocusStyle: {
    color: colors[`gray-cold-800`],
    opacity: 0.9
    // fontSize: '215px',
    // 18px
  },
  underlineStyle: {
    borderWidth: '2px',
    color: colors[`gray-cold-600`]
  },
  underlineFocusStyle: {
    borderColor: colors[`indigo-300`],
    opacity: 1
  },
  errorStyle: {
    position: `absolute`,
    bottom: `-1em`,
    left: `.95em`,
    fontWeight: `bold`,
    textTransform: `uppercase`,
    fontSize: `.7em`,
    letterSpacing: `.015em`,
    color: colors[`danger-500`]
  }
};
