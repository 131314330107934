import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Offset } from 'components/Containers';

export default class CardNews extends React.Component {
  render () {
    const propClasses = this.props.className;
    const classes = classNames(
      `ptb-30em`,
      {
        [`bg-${this.props.bgColor}`]: true
      },
      ...propClasses
    );
    return (
      <header className={classes}>
        <Offset>
          <h1 className={`mb-0 pb-0 fs-20em`}>{this.props.title}</h1>
        </Offset>
      </header>
    );
  }
}
CardNews.propTypes = {
  title: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  className: PropTypes.string
};
CardNews.defaultProps = {
  bgColor: 'indigo-500',
  className: ''
};
