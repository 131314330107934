import React from 'react';
import classNames from 'classnames';
import { StaticQuery, Link, graphql, navigate } from 'gatsby';
import { Fluid, Offset } from 'components/Containers';
import { Col, Container, Row } from 'reactstrap';
import logger from 'utils/logger';

class Component extends React.Component {
  render () {
    const { data } = this.props;
    // logger.gql(data);
    if (!data) { return <LoadingView />; }
    // const networks = this.props.networks;
    const propClasses = this.props.className;
    // const propButtonClasses = this.props.btnClassName;
    const classes = classNames(
      'footer',
      'pt-55em',
      'pb-45em',
      'bg-indigo-900-hard',
      ...propClasses
    );
    const categoriesQuery = data.allContentfulCategory.edges;
    // logger.verbose(`categoriesQuery`, categoriesQuery);

    const menuCMS = data.allContentfulMenu.edges;
    const menuFooter = [
      {
        key: `menu-footer-1`,
        links: []
      },
      {
        key: `menu-footer-2`,
        links: []
      },
      {
        key: `menu-footer-3`,
        links: []
      },
      {
        key: `menu-footer-4`,
        links: []
      },
    ];
    // logger.verbose(`menuCMS`, menuCMS);
    menuCMS.forEach((menu) => {
      // logger.verbose(`menuCMS > menu`, menu.node);
      if (menu.node.location === `Footer 1`) { menuFooter[0].links = menu.node.fields.links; }
      else if (menu.node.location === `Footer 2`) { menuFooter[1].links = menu.node.fields.links; }
      else if (menu.node.location === `Footer 3`) { menuFooter[2].links = menu.node.fields.links; }
      else if (menu.node.location === `Footer 4`) { menuFooter[3].links = menu.node.fields.links; }
    });
    // logger.verbose(`menuFooter`, menuFooter);

    return (
      <footer className={classes}>
        <Fluid>
          <Offset>
            <Container fluid>
              <Row>
                <Col xs={12} md={4} lg={3} className={`mb-20em`}>
                  <div>
                    <img src="/logo-light-nav.png" alt="Apantac" className="logo" />
                    {/*
                      <img src="/logo-light-10y.png" alt="Apantac" style={{maxWidth: `180px`}} />
                    */}
                  </div>
                  <div className={`color-gray-cold-400 copyright`}>
                    <span>Copyright © {new Date().toISOString().substr(0, 4)} Apantac LLC.</span>
                  </div>
                  <div className={`color-gray-cold-400 legal`}>
                    <Link to="/tos">Terms of Use</Link>
                    <span className={`separator`}>|</span>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </div>
                </Col>
                <Col xs={12} md={8} lg={9}>
                  <Container fluid>
                    <Row>
                      {
                        menuFooter && menuFooter.length > 0 && (
                          menuFooter.map((menu) => {
                            let parentPage = null;
                            return (
                              <Col xs={12} md={3} key={menu.key} className={`ml-0 pl-0`}>
                                <ul className={`footer-nav pl-0`}>
                                  {
                                    menu.links && menu.links.length > 0 && (
                                      menu.links.map((link, i) => {
                                        const n = i + 1;
                                        let label;
                                        let to;

                                        if (link.internal.type === `ContentfulPage`) {
                                          label = link.title === `Articles` ? `News` : link.title;
                                          const parentPageLink = link.parentPage;
                                          // logger.debug(`link`, link, parentPageLink)
                                          to = `/${parentPageLink ? `${parentPageLink.slug}/` : ``}${link.slug}`;
                                          if (to === `/company`) {
                                            to = `/company/about-us`;
                                          }
                                          parentPage = link.slug;
                                        }
                                        else if (link.internal.type === `ContentfulCategory`) {
                                          const types = link.contentTypes;
                                          label = link.name;
                                          // logger.debug(`footer ContentfulCategory types`, label, types, parentPage)
                                         
                                          to = `/${parentPage === 'articles' ? `media/` : ``}${parentPage === `support` ? `company/contact` : parentPage}?category=${link.slug}`;
                                          if (types.includes(`productSeries`)) {
                                          } else if (types.includes(`contacts`)  && parentPage !== 'media' && parentPage !== 'articles') {
                                            to = `/company/contact#${link.slug}`
                                          }
                                        }


                                        return <li key={`${menu.key}-${link.id}`} className={`${n > 1 && `d-none d-lg-block`}`}><Link to={to}>{`${label}`}</Link></li>;
                                      })
                                    )
                                  }
                                </ul>
                              </Col>
                            );
                          })
                        )
                      }
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Offset>
        </Fluid>
      </footer>
    );
  }
}

const query = graphql`
  query {
    allContentfulCategory(
      filter: {
        contentTypes: {
          in: ["productGroups"]
        }
      }
    ){
      edges{
        node{
          id
          ... Category
        }
      }
    }
    allContentfulMenu{
      edges{
         node{
          id
          name
          location
          fields{
            links{
              id
              name
              title
              internal{
                type
              }
              slug
              contentTypes
              parentPage{
                slug
              }
            }
          }
        }
      }
    }
  }
`;

export default ({ children }) => (
  <StaticQuery
    query={query}
    render={data => (
      <>
        <Component data={data}/>
      </>
    )}
  />
);
