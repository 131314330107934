import React, { Component } from 'react';
import LoadingView from 'components/Loaders/LoadingView';
// import NavigationItem from './NavigationItem';
import Search from 'components/Search/Search';
import logger from 'utils/logger';
import { Link } from 'gatsby';
import { Row, Col } from 'reactstrap';

import CardProductSeries from 'components/Cards/CardProductSeries';
import Timer from 'components/Loaders/Timer';
import numeral from 'numeral';
import matchSorter from 'match-sorter';

class VerticalNavigationList extends Component {
  constructor (...rest) {
    super(...rest);
    this.state = {
      hits: null,
      query: null,
      fetchingResults: true
    };
  }
  render () {
    const {
      currentSlug,
      data
      // edges,
      // articles,
      // downloads,
      // productSeries,
      // searchData
    } = this.props;

    logger.gql(`data`, data);
    if (
      !data
    ) { return <LoadingView />; }

    const articles = data.allContentfulArticle.edges;
    const downloads = data.allContentfulDownloads.edges;
    const productSeries = data.allContentfulProductSeries.edges;
    const searchData = data.siteSearchIndex;

    const { hits, query } = this.state;
    // logger.verbose(`edges`, edges);
    logger.verbose(`query`, query);
    logger.verbose(`hits`, hits);

    const hitsFilter = matchSorter(hits, query, {
      keys: [
        'type',
        'title',
        'name',
        'categories',
        'content',
        'productLine',
        'productGroup',
        'products',
        'keywords'
      ],
      threshold: matchSorter.rankings.EQUALS
    });
    // logger.verbose(`hitsFilter`, hitsFilter);

    const indexArticles = 0;
    const indexSeries = 0;

    const resultsArticles = articles.filter(({ node }) =>
      !hitsFilter || hitsFilter.filter(hit => hit.id === node.id).length > 0);
    const resultsResources = downloads.filter(({ node }) =>
      !hitsFilter || hitsFilter.filter(hit => hit.id === node.id).length > 0);
    const resultsSeries = productSeries.filter(({ node }) =>
      !hitsFilter || hitsFilter.filter(hit => hit.id === node.id).length > 0);

    // logger.verbose(`resultsArticles`, resultsArticles);
    // logger.verbose(`resultsResources`, resultsResources);
    // logger.verbose(`resultsSeries`, resultsSeries);

    return (
      <div>
        <Search
          data={searchData}
          onSearch={(text, hits) =>
            this.setState({
              hits: text !== '' ? hits : null,
              query: text !== '' ? text : null
            })
          }
        />
        <div>
          {!hits ? (
            <div><h1 className="color-gray-cold-300 fw-300">Search apantac.com...</h1></div>
          ) : (
            <div className="resultsContainer">
              {hits.length === 0 ? (
                <div>
                  <Timer>No results found.</Timer>
                </div>
              ):(
                <div>
                  {resultsSeries.length > 0 && (
                    <div className={`ptb-20em`}>
                      <h1 className={`fs-15em`}>Products</h1>
                      <hr />
                      <Row>
                        {resultsSeries.map((edge) => {
                          const { node } = edge;
                          // logger.debug(`node`, node.name, node);
                          const cardProps = {
                            ...node,
                            productLine: node.productLine && node.productLine.name,
                            productGroup: node.productGroup && node.productGroup.name,
                            productLineSlug: node.productLine && node.productLine.slug,
                            productGroupSlug: node.productGroup && node.productGroup.slug
                          };
                          return (
                            <Col
                              key={`node-productSeries-${node.id}`}
                              xl={2}
                              md={3}
                              sm={4}
                            >
                              <CardProductSeries {...cardProps} />
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  )}
                  {resultsArticles.length > 0 && (
                    <div className={`ptb-20em`}>
                      <h1 className={`fs-15em`}>Articles</h1>
                      <hr />
                      <Row>
                        {resultsArticles.map((edge) => {
                          const { node } = edge;
                          const article = node;
                          // logger.debug(`node`, node.title, node);
                          let excerpt = ``;
                          if (article.excerpt) {
                            if (article.excerpt.excerpt) {
                              excerpt = article.excerpt.excerpt;
                            }
                          } else if (article.content) {
                            if (article.content.childMarkdownRemark.excerpt) {
                              excerpt = article.content.childMarkdownRemark.excerpt;
                            }
                          }
                          return (
                            <Col md={6} lg={4} key={`nav-header-wrapper-${node.id}`}>
                              <article className={`mb-30em`}>
                                <div className="card-content prl-0">
                                  {
                                    article.categories && (
                                      article.categories.length > 0 && article.categories.map((item) => {
                                        return (
                                          <span key={`categories-${item.slug}`}>
                                            <Link
                                              to={item.slug ? `/media?category=${item.slug}` : '/media'}
                                              className={`fs-08em fw-600 tt-uppercase category color-cyan-400 category-${item.slug}`}
                                            >
                                              {item.name}
                                            </Link>
                                          </span>
                                        );
                                      })
                                    )
                                  }
                                  <hr style={{ marginTop: `1.2em !important`, marginBottom: `1.2em !important` }} />
                                  <h1 className={`fs-13em lh-14 fw-500 ls--30em mb-5em pr-20em`}>
                                    <Link to={`/media/articles/${article.categories ? article.categories[0].slug : `c`}/${article.slug}`} className={`color-gray-cold-700 color-black-hover`}>
                                      {article.title}
                                    </Link>
                                  </h1>
                                  {excerpt && <p className={`mb-15em`}>{excerpt}</p>}
                                  <dl className={`fs-08em color-gray-cold-300`}>
                                    <dd>
                                      <time itemProp="datePublished">
                                        {article.createdAtLegacy ? article.createdAtLegacy : article.createdAt}
                                      </time>
                                    </dd>
                                  </dl>
                                </div>
                              </article>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  )}
                  {resultsResources.length > 0 && (
                    <div className={`ptb-20em`}>
                      <h1 className={`fs-15em`}>Resources</h1>
                      <hr />
                      <Row>
                        {resultsResources.map((edge) => {
                          const { node } = edge;
                          const download = node;
                          if (download.resource) {
                            // logger.debug(`node`, node.name, node);
                            let downloadType = download.type;
                            if (downloadType === `document`) {
                              downloadType = `support`;
                            }
                            let icon;
                            if (downloadType === `datasheet`) {
                              icon = `File-Chart`;
                            } else if (
                              downloadType === `manual` ||
                              downloadType === `support`
                            ) {
                              icon = `Book`;
                            } else if (downloadType === `catalog`) {
                              icon = `Address-Book2`;
                            } else {
                              icon = `Download-fromCloud`;
                            }
                            let extension = download.resource.file.contentType.split(`/`).pop();
                            if (extension === `x-dosexec`) { extension = `Executable`; }
                            if (extension === `octet-stream`) { extension = `Executable`; }
                            return (
                              <Col lg={6} xl={4} key={`download-${download.id}`} className={`mb-10em`}>
                                <a
                                  href={download.resource.file.url}
                                  alt={download.resource.file.fileName}
                                  target={`_blank`}
                                  className={`color-gray-cold-700 color-primary-600-hover`}
                                >
                                  <div>
                                    <span className={`inline-block prl-0 pos-relative`} style={{ top: `-5px` }}>
                                      <i className={`icon icon-${icon} fs-15em mr-5em color-primary-500`} />
                                    </span>
                                    <span className={`inline-block pl-10em fs-09em`}>
                                      <span><b>{download.name}</b></span>
                                      <br />
                                      <span className={`alpha-70 fs-08em tt-uppercase`}>{extension} | {numeral(download.resource.file.details.size).format('0.0 b')}</span>
                                    </span>
                                  </div>
                                </a>
                              </Col>
                            );
                          }
                        })}
                      </Row>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default VerticalNavigationList;
