import React from 'react';
import logger from 'utils/logger';
import { Container, Col, Row } from 'reactstrap';

export default class LoadingView extends React.Component {
  render () {
    logger.file(__filename, '>', 'render()');
    const sourceURL = `https%3A//apantac.com`;
    const title = `Share Title`;
    const summary = `Share summary`;
    const hashtags = `hashtag1,hashtag2,hashtag3`;
    let urlToShare;
    if (typeof window !== 'undefined') {
      urlToShare = window.location.href;
    }
    const emailBody = `${summary}. Find out more at ${urlToShare}.`;
    logger.data('urlToShare', urlToShare);
    logger.groupEnd();
    return (
      <Container>
        Loading...
      </Container>
    );
  }
}
