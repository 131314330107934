import SimpleSchema from 'simpl-schema';
import {
  EmailSchema,
  Honeypot
} from 'api/schemas/_Global';

const NewsletterSubscribeSchema = new SimpleSchema({
  // picture: { type: String, optional: true }
}).extend(EmailSchema).extend(Honeypot);
export default NewsletterSubscribeSchema;
