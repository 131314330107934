import React from 'react';
// import { Link } from 'gatsby';
import { StaticQuery, graphql, navigate, Link } from 'gatsby';

import logger from 'utils/logger';

class MainNavbar extends React.Component {
  constructor (props) {
    super(props);
    logger.verbose('props MainNavbar', props)
    this.state = {
      isOpen: false
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleGoTo = this.handleGoTo.bind(this);
  }
  // componentDidMount () {
  //   this.setState({
  //     authenticated: this.props.auth.isAuthenticated()
  //   });
  // }
  handleToggle () {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  login () {
    // logger.verbose(`login`);
    this.props.auth.login();
  }

  logout () {
    // logger.verbose(`logout`);
    this.props.auth.logout();
  }
  handleGoTo (target) {
    // logger.verbose(`handleGoTo (${target})`);
    this.setState({ isOpen: false });
    navigate(target);
  }

  render () {
    // logger.component(__filename, '>', 'render()');
    const { data, location, user } = this.props;
    // logger.gql(data);

    const { isAuthenticated } = this.props.auth;
    // let user = null;
    if (isAuthenticated) {
      // logger.debug(`isAuthenticated() then this.props.auth)`, this.props.auth);
      // user = this.props.user;
      // logger.currentUser(`isAuthenticated() user`, user);
      // logger.debug(`isAuthenticated() user`, user);
    } else {
      // logger.info(`isAuthenticated false`);
    }
    // logger.debug(`location`, location);
    let menu = [];
    if (data.contentfulMenu) {
      if (data.contentfulMenu.fields) {
        if (data.contentfulMenu.fields.links) {
          menu = data.contentfulMenu.fields.links;
        }
      }
    }
    // logger.debug(`menu`, menu.lenght, menu);
    // logger.currentUser(__filename, user);
    // logger.componentEnd();
    return (
      <nav className={`navbar navbar-expand-lg navbar-dark bg-indigo-900-hard ${this.state.isOpen && `fixed-top`}`} id={`Navigation`}>
        <Link className="nav-link logo" role="none" to={`/`}>
          <span className="emblem">
            <img
              src={`/img/brand-icon-nav.png`}
              alt="Apantac Icon"
              style={{
                width: `82%`,
                height: `82%`,
                padding: `15% 5% 3% 9%`
              }}
            />
          </span>
          <img src={`/logo-light-nav.png`} alt={`Apantac`} className="logo" />
          {/* <h1 className="navbar-brand mb-0">{title}</h1> */}
        </Link>
        <button
          onClick={this.handleToggle}
          className={`navbar-toggler navbar-toggler-right ${!this.state.isOpen && `collapsed`}`}
          aria-controls="Navigation"
          aria-expanded={this.state.isOpen}
          aria-label="Toggle navigation"
        >
          <span />
          <span />
          <span />
          <span />
        </button>
        <div className={`collapse navbar-collapse ${this.state.isOpen && `show`}`}>
          <ul className="navbar-nav mr-auto">
            { menu && menu.map((nav) => {
              // logger.debug(`contentfulMenu > nav`, nav);
              const label = nav.title ? nav.title : nav.name;
              const parent = nav.parentPage ? nav.parentPage.slug : null;
              const { slug } = nav;
              const to = parent ? `/${parent}/${slug}` : `/${slug}`;
              let active;
              if (location) {
                active = location.pathname.startsWith(to);
                if (location.pathname.includes(`contact`) && slug !== `contact`) {
                  active = false;
                }
              }
              return (
                <li key={`mainNav-${nav.id}`} className={`nav-item ${active ? 'active' : ''}`}>
                  <Link className="nav-link" role="none" to={to === `/company` ? `/company/about-us/` : to}>
                    {label}
                  </Link>
                </li>
              );
            })}
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className={`nav-item`}>
              <Link className="nav-link mr-10em" role="none" to={`/search`}>
                <span className={`d-none d-lg-inline`}><i className="icon icon-Magnifi-Glass2 fw-800 fs-13em pos-absolute" /></span>
                <span className={`d-inline d-lg-none`}>Search</span>
              </Link>
            </li>
            {!user && (
              <li className={`nav-item`}>
                <a
                  onClick={this.login.bind(this)}
                  role="none"
                  className="nav-link"
                >
                  Log In
                </a>
              </li>
            )}
            {user && (
              <li className={`nav-item ${location && (`${location.pathname.startsWith('/account') ? 'active' : ''}`)}`}>
                <Link className="nav-link" role="none" to={`/account/profile`}>
                  {
                    user && (
                      <span className={`avatar-container`}>
                        <span className="avatar avatar-32 mr-5em">
                          <img src={user.picture} alt={`${user.given_name}'s avatar`} />
                        </span>
                        {user.given_name}
                      </span>
                    )
                  }
                </Link>
              </li>
            )}
            {user && (
              <li className={`nav-item`}>
                <a
                  onClick={this.logout.bind(this)}
                  role="none"
                  className="nav-link"
                >
                  Log Out
                </a>
              </li>
            )}
          </ul>
        </div>
      </nav>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query MainNavbarQuery {
        contentfulMenu(name: {eq: "Main Navigation"}){
          name
          fields{
            links {
              id
              title
              name
              slug
              parent
              parentPage {
                slug
              }
            }
          }
        }
      }
    `}
    render={data => (
      <MainNavbar data={data} {...props} />
    )}
  />
)
