import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

class Timer extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      seconds: 0,
      completed: false
    };
  }

  componentDidMount () {
    this.interval = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount () {
    clearInterval(this.interval);
  }

  tick () {
    if (this.state.seconds < 3) {
      this.setState(prevState => ({
        seconds: prevState.seconds + 1
      }));
    } else {
      clearInterval(this.interval);
      this.setState({ completed: true });
    }
  }

  render () {
    return (
      <div>
        {
          this.state.completed ? (
            <div>{this.props.children}</div>
          ) : (
            <div>
              <CircularProgress
                variant="indeterminate"
                size={24}
                thickness={6}
              />
            </div>
          )
        }
      </div>
    );
  }
}

export default Timer;
