import React from 'react';
// import { Link } from 'gatsby';
import { StaticQuery, graphql, Link } from 'gatsby';
import CookieConsent from "react-cookie-consent";
import logger from 'utils/logger';
// logger.file(__filename);
// Load our Auth component
import Auth from 'utils/Auth/Auth';
// Apollo Client
// import ApolloClient from 'apollo-client';
// import { createHttpLink } from 'apollo-link-http';
// import { InMemoryCache } from 'apollo-cache-inmemory';
// import { setContext } from 'apollo-link-context';
// import { withClientState } from 'apollo-link-state';
// import { ApolloProvider } from 'react-apollo';
import { StickyContainer } from 'react-sticky';
import get from 'lodash/get';
import { Container } from 'reactstrap';
import Helmet from 'react-helmet';
// import { rhythm, scale } from '../utils/typography';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'; // v1.x
import { MuiThemeProvider as V0MuiThemeProvider} from 'material-ui';
import { ParallaxProvider } from 'react-scroll-parallax';
// import 'animate.css/animate.css'
import MainNavbar from 'components/Navigation/MainNavbar';
import Subscribe from 'components/CTAs/Subscribe';
import Footer from 'components/Footer/Footer';
// main site style
import { siteMetadata } from '../../gatsby-config';
import 'layouts/index.scss';
import { theme, muiTheme } from 'layouts/jss';
// import SocialNetworksFragment from 'api/graphql/fragments/SiteMeta';
import JssProvider from 'react-jss/lib/JssProvider';
import { createGenerateClassName } from '@material-ui/core/styles';

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
});

const auth = new Auth();
// Create Apollo Client
// const httpLink = createHttpLink({
//   uri: process.env.GATSBY_GRAPHQLCMS_ENDPOINT
// });
// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const user = auth.getUserInfo();
//   const token = process.env.GATSBY_GRAPHQLCMS_TOKEN;
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: user ? `Bearer ${token}` : ''
//     }
//   };
// });

// const defaults = {
//   nodes: []
// };

// const cache = new InMemoryCache();
// const stateLink = withClientState({ cache, defaults });
// const client = new ApolloClient({
//   link: authLink.concat(httpLink),
//   cache
// });

class Template extends React.Component {
  constructor (props) {
    super(props);
    const currentUser = typeof localStorage !== 'undefined' && localStorage.getItem('currentUser');
    this.state = {
      currentUser
    };
  }

  componentDidMount () {
    const currentUser = typeof localStorage !== 'undefined' && localStorage.getItem('currentUser');
    if (currentUser !== this.state.currentUser) {
      this.setState({ currentUser });
    }
    logger.info(`currentUser`, currentUser);
  }

  componentDidUpdate () {
    const currentUser = typeof localStorage !== 'undefined' && localStorage.getItem('currentUser');
    if (currentUser !== this.state.currentUser) {
      this.setState({ currentUser });
    }
  }

  render () {
    logger.layout(__filename);
    // const props = this.props;
    const { children } = this.props;
    logger.info('layout state', this.state);
    logger.info('layout props', this.props);

    // var user = null;
    // if (this.state.authenticated) {
    // }

    const { isAuthenticated } = auth;

    // let userInfo;
    logger.verbose(`isAuthenticated`, isAuthenticated)
    const user = auth.getUserInfo();
    if (isAuthenticated) {
      auth.getUserInfo();
    }

    logger.verbose(`index user`, user);
    logger.verbose(`index getUserInfo`, auth.getUserInfo());

    const socialNetworks = get(this, 'props.data.site.siteMetadata.socialNetworks');
    const socialNetworksProps = { socialNetworks };
    const globalProps = {
      auth,
      isAuthenticated,
      location,
      user,
      socialNetworksProps
    };

    let location;
    if (typeof window !== 'undefined') {
      location = window.location;
      // Callback doesn't need nav etc, so return early
      if (window.location.pathname === '/callback') {
        return (
          <div>
            <Container>
              {children}
            </Container>
          </div>
        );
      }
    }

    /*
    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }
    if (location.pathname === rootPath) {
    } else {
    }
    */

    logger.verbose(`isAuthenticated`, isAuthenticated());

    logger.layoutEnd();
    logger.verbose(`location`, location);
    return (
      /*
      <ApolloProvider client={client}>
       */
      <div>
        <MuiThemeProvider theme={theme}>
          <V0MuiThemeProvider muiTheme={muiTheme}>
            <JssProvider generateClassName={generateClassName}>
              <StickyContainer>
                <Helmet>
                  <html lang="en" />
                  <link href="https://s3.amazonaws.com/icomoon.io/114779/Socicon/style.css?9ukd8d" rel="stylesheet" />
                  <meta name="viewport" content="width=device-width, initial-scale=1" />
                  <meta name="google-site-verification" content="UajAhXKLX0tdyWq0YwCN5E948ihyaz66srSRMvHsN7A" />
                </Helmet>
                <CookieConsent
                    location="bottom"
                    buttonText="Got it!"
                    cookieName="cookiesConsentApantacCom"
                    style={{ background: "rgb(2, 2, 16)", fontSize: `0.9em` }}
                    buttonClasses={`btn btn-outline-primary-500 color-indigo-200 color-white-hover`}
                    buttonStyle={{ fontWeight: `600`, background: "rgba(255,255,255, 0.1)", borderRadius: "4px", fontSize: "13px", border: "2px solid #03a9f4" }}
                    expires={150}
                >
                  Apantac uses cookies to ensure you get the best experience on our website.{" "}
                  <Link to={`/privacy-policy`}>Learn More</Link>.
                </CookieConsent>
                <ParallaxProvider>
                  <div className="container-fluid App prl-0">
                    <MainNavbar title={siteMetadata.title} {...globalProps} location={location} />
                    <main>
                      {children}
                    </main>
                    <Subscribe {...socialNetworksProps} />
                    <Footer />
                  </div>
                </ParallaxProvider>
              </StickyContainer>
            </JssProvider>
          </V0MuiThemeProvider>
        </MuiThemeProvider>
      </div>
      /*
      </ApolloProvider>
       */
    );
  }
}

const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
      ...SiteSocialNetworks
    }
  }
`;
export default ({ children, location }) => (
  <StaticQuery
    query={query}
    render={data => (
      <>
        <Helmet titleTemplate={`%s | ${data.site.siteMetadata.title}`} defaultTitle={data.site.siteMetadata.title} />
        <Template location={location}>
          {children}
        </Template>
      </>
    )}
  />
);


/*
{ this.state.authenticated ?
  <div>
    <h2>{ `Welcome Back ${ user.given_name }` }</h2>
    <p>This is a private section only available for logged in users, if you can see it you are logged in!</p>
  </div>
  :
  <h2>Hey man! Log in to see this section</h2>
}
{header}
footer()*/
// logger.fileEnd();
