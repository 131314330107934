import auth0 from 'auth0-js';
import { navigate } from 'gatsby'
import qs from 'qs';
import Cookies from 'js-cookie';

import { AUTH_CONFIG } from 'utils/Auth/auth0-variables';
import logger from 'utils/logger';

export default class Auth {
  auth0 = new auth0.WebAuth({
    audience: `https://${AUTH_CONFIG.domain}/api/v2/`,
    scope: 'openid profile email',
    responseType: 'token id_token',
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    domain: AUTH_CONFIG.domain,
  })
 
  login = () => {
    console.log(`fofofofo`)
    this.auth0.authorize({}, function(err, response) {
      if (err) {
        logger.debug(err);
      } else {
        logger.debug(`this.auth0.authorize`, response);
      }
    });
  }
 
  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.renewSession = this.renewSession.bind(this);
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user');
    navigate('/'); 
  }

  handleAuthentication() {
    if (typeof window !== 'undefined') {
      const hash = window.location.hash
      const querystring = qs.parse(window.location.search.substring(1));
      logger.debug(`handleAuthentication() > querystring`, querystring);
      const state = querystring.state;
      const authCookie = Cookies.get(`com.auth0.auth.${state}`);
      logger.debug(`handleAuthentication() > authCookie`, authCookie);
      const authCookieParse = authCookie ? JSON.parse(authCookie) : '';
      logger.debug(`handleAuthentication() > authCookieParse`, authCookieParse);

      const nonce = authCookieParse.nonce;
      const code = querystring.code;
      logger.debug(`handleAuthentication() > code`, code);
      logger.debug(`handleAuthentication() > nonce`, nonce);

      logger.debug(`handleAuthentication() > this.auth0`, this.auth0);

      logger.debug(`handleAuthentication() window.location`, window.location);
      logger.debug(`handleAuthentication() hash`, hash);

      
      this.auth0.parseHash(async (err, authResult) => {
        if (err) {
          logger.debug(`err`, err);
          // this.router.navigate('/']); 
        }

        else if (authResult && authResult.accessToken && authResult.idToken) { 
          window.location.hash = ''; 
          logger.debug(`authResult`, authResult)
          this.setSession(authResult); 
          // this.router.navigate('/']); 
        } 
        else {
          logger.debug(`wtf authResult`, authResult)
        }

        // Return to the homepage after authentication.
        // navigate('/account/profile');
      });
      /*
       this.auth0.parseHash((err, authResult) => { 
        if (err) {
          console.log(`err`, err);
          // this.router.navigate('/']); 
        }

        else if (authResult && authResult.accessToken && authResult.idToken) { 
          window.location.hash = ''; 
          this.setSession(`authResult`, authResult); 
          logger.debug(`authResult`, authResult)
          // this.router.navigate('/']); 
        } 
        else {
          logger.debug(`wtf authResult`, authResult)
        }
      });
       */



    }
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  isAuthenticated() {
    if (typeof window !== 'undefined') {
      const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
      return new Date().getTime() < expiresAt;
    }
  }
  renewSession() {
    this.auth0.checkSession({}, (err, authResult) => {
       if (authResult && authResult.accessToken && authResult.idToken) {
         this.setSession(authResult);
       } else if (err) {
         this.logout();
         console.log(err);
         alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
       }
    });
  }


  setSession(authResult) {
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);

    this.auth0.client.userInfo(authResult.accessToken, (err, user) => {
      // const permissions = typeof window !== 'undefined' && user[`${window.location.protocol}//${window.location.host}/`];
      
      const permissions = {
        groups: Object.values(user).map(value => value.groups).filter(k => k != null)[0],
        permissions: Object.values(user).map(value => value.permissions).filter(k => k != null)[0],
        roles: Object.values(user).map(value => value.roles).filter(k => k != null)[0]
      };
      let userData = user;
      user.permissions = permissions;
      localStorage.setItem('user', JSON.stringify(user));
    })
  }

  getUser() {
    if (typeof window !== 'undefined') {
      if (localStorage.getItem('user')) {
        return JSON.parse(localStorage.getItem('user'));
      }
    }
  }

  getUserName() {
    if (this.getUser()) {
      return this.getUser().name;
    }
  }
  
  getUserInfo() {
    if (this.getUser()) {
      return this.getUser();
    }
  }
}
