import React from 'react'
import classNames from 'classnames'
import { Container, Col } from 'reactstrap'

export class Fluid extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="mc-fluid">
        {this.props.children}
      </div>
    )
  }
}
export class Offset extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    var propClasses = this.props.className;
    var classes = classNames(
      'col', 
      'mc-offset',
      ... propClasses
    );
    return (
      <div className={classes}>
        {this.props.children}
      </div>
    )
  }
}