let namespace;
let activeEnv = process.env.ACTIVE_ENV;
if (typeof window !== 'undefined') {
  namespace = `${window.location.origin}`;
}

export const AUTH_CONFIG = {
  domain: process.env.GATSBY_AUTH0_DOMAIN,
  clientId: process.env.GATSBY_AUTH0_CLIENT_ID,
  callbackUrl: process.env.GATSBY_AUTH0_CALLBACK_URL,
  container: 'auth0-login-container'
};
export { AUTH_CONFIG as default };
