import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';
import logger from 'utils/logger';
import AutoForm from 'uniforms-material/AutoForm';
import TextField from 'uniforms-material/TextField';
import SubmitField from 'uniforms-unstyled/SubmitField';

import NewsletterSubscribeSchema from 'api/schemas/NewsletterSubscribeSchema';
import { TextFieldDark } from 'layouts/jss';

class Subscribe extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      // form: {},
      error: false,
      success: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange (key, value) {
    // logger.info('key', key, 'value', value);
    this.setState({
      error: false
    //   form: { [key]: value }
    });
  }
  handleSubmit (doc) {
    // logger.group(__filename, '>', 'submitHandler(doc)');
    // logger.info('doc', doc);
    // logger.info('this', this);

    const request = {
      email_addresses: [
        {
          email: doc.email,
          field: 'EMAIL1'
        }
      ],
      duplicate_option: 'Email',
      opt_in_reason: 'Customer opted-in through webform',
      source_type: 'WEBFORM'
    };

    const body = JSON.stringify(request);

    // logger.info('body', body);

    const ENDPOINT = `https://api.infusionsoft.com/crm/rest/v1/contacts?access_token=${process.env.GATSBY_INFUSIONSOFT_TOKEN}`;
    fetch(ENDPOINT, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body
    }).then((res) => {
      // logger.verbose(`res`, res);
      if (res) {
        this.setState({
          success: true,
          error: false
        });
      } else {
        // this.setState({
        //   success: false,
        //   error: true
        // });
      }
    }).catch((error) => {
      // logger.verbose(`error`, error);
      this.setState({
        success: false,
        error: true
      });
    });

    // logger.groupEnd();
  }
  render () {
    // logger.file(__filename, '>', 'render()');
    // logger.verbose('NewsletterSubscribeSchema', NewsletterSubscribeSchema);
    const { data } = this.props;
    const socialNetworks = data.allContentfulSocialNetworks ? data.allContentfulSocialNetworks.edges : [];
    // logger.verbose('socialNetworks', socialNetworks);
    const propClasses = this.props.className;
    // const propButtonClasses = this.props.btnClassName;
    const classes = classNames(
      'subscribe',
      'ptb-25em',
      'bg-indigo-500',
      'text-center',
      ...propClasses
    );
    // logger.debug(`TextFieldDark`, TextFieldDark);
    // logger.fileEnd();
    return (
      <aside className={classes}>
        <AutoForm
          schema={NewsletterSubscribeSchema}
          showInlineError
          onSubmit={this.handleSubmit}
          // onSubmitSuccess={() => logger.success('Promise resolved!')}
          // onSubmitFailure={() => logger.fail('Promise rejected!')}
          validate="onChangeAfterSubmit"
          onChange={(key, value) => {
            this.handleChange(key, value);
          }}
        >
          <div className={`p-15em`} style={{ position: `relative` }}>
            { this.state.success ? (
              <div className={`inline-block mr-30em pb-6em`}>
                <div className="fs-15em">Thank you for subscribing!</div>
              </div>
            ) : (
              <span>
                <div className={`inline-block mr-30em mb-10em`}>
                  <div className="tt-uppercase ">Stay in touch</div>
                </div>
                <div className={`inline-block mr-5em mb-10em`} style={{ height: `2em`, position: `relative` }}>
                  <TextField
                    name="email"
                    label="Email Address"
                    className={`mui mui-subscribe`}
                  />
                </div>
              </span>
            )}
            <div className={`inline-block mr-30em`}>
              <div className="">
                { !this.state.success && (
                  <SubmitField
                    value="Subscribe"
                    className={`mui btn btn-outline-indigo-300 ls-400em color-indigo-200 color-white-hover ptb-12em pl-25em pr-20em`}
                  />
                )}
                <span className="shr cta ml-10em">
                  { socialNetworks && socialNetworks.map((edge) => {
                    const socialNetwork = edge.node;
                    // logger.debug(`socialNetwork`, socialNetwork);
                    return (
                      (socialNetwork.network && socialNetwork.url) && (
                        <a key={`network-${socialNetwork.network}`} target={`_blank`} href={socialNetwork.url} className={`ml-5em color-indigo-400 color-indigo-200-hover`}>
                          <i className={`socicon socicon-${socialNetwork.network.toLowerCase()}`} />
                        </a>
                      )
                    );
                  })}
                </span>
              </div>
            </div>
            { this.state.error && (
              <span
                className={`prl-20em color-danger-300 fw-600`}
                style={{
                  width: `100%`,
                  fontSize: `12px`,
                  position: `absolute`,
                  bottom: `-1em`,
                  left: `50%`,
                  transform: `translateX(-50%)`,
                  textAlign: `center`
                }}
              >
                Something went wrong trying to subscribe you. Please verify your email or try again later.
              </span>
            )}
          </div>
        </AutoForm>
      </aside>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query SubscribeQuery {
        allContentfulSocialNetworks(sort: {fields: sort}){
          edges{
            node{
              network
              url
            }
          }
        }
      }
    `}
    render={data => (
      <Subscribe data={data} {...props} />
    )}
  />
)
