import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';

const Img = ({ objFit = `cover`, objPosition = `50% 50%`, ...props }) => (
  <Image
    {...props}
    imgStyle={{
      ...props.imgStyle,
      objectFit: props.imgStyle && (props.imgStyle.objectFit ? props.imgStyle.objectFit : objFit),
      objectPosition: props.imgStyle && (props.imgStyle.objectPosition ? props.imgStyle.objectPosition : objPosition),
      fontFamily: `"object-fit: ${props.imgStyle && (props.imgStyle.objectFit ? props.imgStyle.objectFit : objFit)}; object-position: ${props.imgStyle && (props.imgStyle.objectPosition ? props.imgStyle.objectPosition : objPosition)}"`
    }}
  />
);

export default Img;

Img.propTypes = {
  objFit: PropTypes.string,
  objPosition: PropTypes.string
};
Img.defaultProps = {
  objFit: `cover`,
  objPosition: `50% 50%`
};
