import React, {Component} from 'react';
import Layout from '../components/Layout';
import { StaticQuery, graphql } from 'gatsby';
import {Index} from 'elasticlunr';
import VerticalNavigationList from 'components/Search/VerticalNavigationList';
// import graphql from 'graphql-tag';
import Helmet from 'react-helmet';
import SimpleHeader from 'components/Headers/SimpleHeader';
import { Fluid, Offset } from 'components/Containers';
// Graphql query used to retrieve the serialized search index.

const Template = ({
  data: {
    allContentfulArticle,
    allContentfulProductSeries,
    allContentfulDownloads,
    site,
    siteSearchIndex
  }
}) => (
  <Layout>
    <Fluid>
      <SimpleHeader title={`Search Apantac`} />
      <section className={``}>
        <Offset>
          <div className={'master-detail-container ptb-30em'}>
            <div className={'master-pane'}>
              <VerticalNavigationList
                currentSlug={'/'}
                articles={[
                  ...allContentfulArticle.edges
                ]}
                downloads={[
                  ...allContentfulDownloads.edges
                ]}
                productSeries={[
                  ...allContentfulProductSeries.edges
                ]}
                searchData={siteSearchIndex}
              />
            </div>
          </div>
        </Offset>
      </section>
    </Fluid>
  </Layout>
);

const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    siteSearchIndex {
      index
    }
    allContentfulArticle(sort: { fields: [createdAt], order: DESC}){
      edges{
        node {
          id
          ... Article
          internal {
            type
          }
        }
      }
    }
    allContentfulDownloads{
      edges{
        node {
          id
          ... Download
        }
      }
    }
    allContentfulProductSeries {
      edges{
        node {
          id
          ... ProductSeriesBase
          internal {
            type
          }
          productLine {
            name
            slug
          }
          thumbnail {
            ... Thumbnail
          }
          products {
            id
            name
          }
        }
      }
    }
  }
`;

export default ({ children }) => (
  <StaticQuery
    query={query}
    render={data => (
      <>
        <Helmet title={`${`Search`}`} />
        <Layout>
          <Fluid>
            <SimpleHeader title={`Search Apantac`} />
            <section className={``}>
              <Offset>
                <div className={'master-detail-container ptb-30em'}>
                  <div className={'master-pane'}>
                    <VerticalNavigationList
                      currentSlug={'/'}
                      data={data}
                    />
                  </div>
                </div>
              </Offset>
            </section>
          </Fluid>
        </Layout>
      </>
    )}
  />
);

