let activeEnv = process.env.ACTIVE_ENV;
// const remark = require('remark');
// import remark from 'remark';

if (!activeEnv) {
  activeEnv = `development`;
  require(`dotenv`).config({
    path: `.env.${activeEnv}`
  });
}

// Overwrite the Contentful config with environment variables if they exist
const contentfulConfig = {
  spaceId: process.env.CONTENTFUL_SPACE,
  accessToken: process.env.CONTENTFUL_DELIVERY_TOKEN
};

module.exports = {
  siteMetadata: {
    title: `Apantac`,
    titleSeparator: `|`,
    titleTemplate: "%s | Apantac",
    author: `PAWA | Digital Agency`,
    description: `Leading designer and developer of high quality, cost effective image signal processing equipment.`,
    image: "/img/apantac-share.jpg",
    twitterUsername: "@apantac",
    siteUrl: `https://apantac.com`,
    url: `https://apantac.com`,
    social_networks: [
      {
        network: `youtube`,
        url: `https://www.youtube.com/user/Apantac`
      },
      {
        network: `facebook`,
        url: `https://www.facebook.com/Apantac-217799654898609/`
      },
      {
        network: `twitter`,
        url: `https://twitter.com/apantac`
      },
      {
        network: `instagram`,
        url: ``
      },
      {
        network: `linkedin`,
        url: `https://www.linkedin.com/company/apantac-llc.`
      }
    ]
  },
  pathPrefix: `/apantac-com`,

  plugins: [
    {
      resolve: `gatsby-plugin-create-client-paths`,
      options: { prefixes: [`/account/*`] }
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        path: `${__dirname}/src/pages`,
        name: `pages`
      }
    },
    // {
    //   resolve: `gatsby-transformer-remark`,
    //   options: {
    //     plugins: [
    //       {
    //         resolve: `gatsby-remark-images`,
    //         options: {
    //           maxWidth: 590
    //         }
    //       },
    //       {
    //         resolve: `gatsby-remark-responsive-iframe`,
    //         options: {
    //           wrapperStyle: `margin-bottom: 1.0725rem`
    //         }
    //       },
    //       `gatsby-remark-prismjs`,
    //       `gatsby-remark-copy-linked-files`,
    //       `gatsby-remark-smartypants`
    //     ]
    //   }
    // },
    `gatsby-transformer-remark`,
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: process.env.GATSBY_GA_TRACKING_ID
      }
    },
    {
      resolve: `gatsby-plugin-linkedin-insight`,
      options: {
        partnerId: process.env.GATSBY_LINKEDIN_INSIGHT_PARTNER_ID,
        // Include LinkedIn Insight in development.
        // Defaults to false meaning LinkedIn Insight will only be loaded in production.
        includeInDevelopment: false
      }
    },
    {
      resolve: `gatsby-source-contentful`,
      options: contentfulConfig
    },
    // `gatsby-plugin-feed`,
    // {
    //   resolve: `gatsby-plugin-resolve-src`,
    //   options: {
    //     addSassLoader: false
    //   }
    // },
    `gatsby-plugin-sass`,
    {
      resolve: `@gatsby-contrib/gatsby-plugin-elasticlunr-search`,
      options: {
        // Fields to index
        fields: [
          'type',
          'title',
          'name',
          'categories',
          'content',
          'productLine',
          'productGroup',
          'products',
          'keywords'
        ],
        // How to resolve each field's value for a supported node type
        resolvers: {
          // For any node of type MarkdownRemark, list how to resolve the fields' values
          MarkdownRemark: {
            title: node => node.frontmatter.title,
            keywords: node => node.frontmatter.keywords
          },
          ContentfulArticle: {
            type: node => 'article',
            title: (node) => {
              return node.title.replace(/[`~!@#$%^&*()_|+\-=?;:'"“”&®™,.<>\{\}\[\]\\\/]/, ``);
            },
            slug: node => node.slug,
            categories: node => node.fields.categories
          },
          ContentfulProductSeries: {
            title: node => node.name,
            tagline: node => node.tagline,
            slug: node => node.slug,
            keywords: node => node.fields.keywords
          },
          ContentfulDownloads: {
            // type: node => 'downloads',
            type: node => node.type,
            title: node => node.name,
            categories: node => node.fields.keywords
          }
        }
      }
    },
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        exclude: [
          `/*.php`,
          `/company/forms`,
          `/company/forms/*`
        ]
      }
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Apantac`,
        short_name: `Apantac.com`,
        start_url: `/`,
        background_color: `#01001d`,
        theme_color: `#0081de`,
        display: `minimal-ui`,
        icons: [
          {
            // Everything in /static will be copied to an equivalent
            // directory in /public during development and build, so
            // assuming your favicons are in /static/favicons,
            // you can reference them here
            src: `/favicons/android-chrome-192x192.png`,
            sizes: `192x192`,
            type: `image/png`
          },
          {
            src: `/favicons/android-chrome-512x512.png`,
            sizes: `512x512`,
            type: `image/png`
          },
          {
            src: `/favicons/apple-touch-icon.png`,
            sizes: `180x180`,
            type: `image/png`
          },
          {
            src: `/favicons/favicon-16x16.png`,
            sizes: `16x16`,
            type: `image/png`
          },
          {
            src: `/favicons/favicon-32x32.png`,
            sizes: `32x32`,
            type: `image/png`
          },
          {
            src: `/favicons/mstile-150x150.png`,
            sizes: `150x150`,
            type: `image/png`
          }
        ]
      }
    },
    {
      resolve: `gatsby-plugin-netlify`,
      options: {
        // option to add more headers. `Link` headers are transformed by the below criteria
        headers: {},
        // option to add headers for all pages. `Link` headers are transformed by the below criteria
        allPageHeaders: [],
        // boolean to turn off the default security headers
        mergeSecurityHeaders: true,
        // boolean to turn off the default gatsby js headers
        mergeLinkHeaders: true,
        // boolean to turn off the default caching headers
        mergeCachingHeaders: true,
        // optional transform for manipulating headers under each path (e.g.sorting), etc.
        transformHeaders: (headers) => headers,
        // boolean to turn off automatic creation of redirect rules for client only paths
        generateMatchPathRewrites: false
      }
    },
    // `gatsby-plugin-offline`,
    'gatsby-plugin-remove-serviceworker'
  ],
  mapping: {
    'ContentfulProductLine.productGroups': `ContentfulProductGroup`
  }
};
